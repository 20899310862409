body{
  padding: 60px 70px 70px 70px;
  font-family: "myriad-pro-n4", "myriad-pro", verdana, sans-serif;
  word-wrap: break-word;
}
.main {
    background-color:transparent !important;
}
.print-logo {
    display: initial !important;
}
.header-bar,
.login,
.search-bar,
.nav,
.store-option,
.multi-select-search,
.breadcrumbs,
.previous-link,
.actions,
.logo,
.cw-contents,
.footer,
.arrow-up{
  display: none;
}
.cw-datastore, .cw-meta{
  display: block;
  margin: 0px;
  color: #808285 !important;
  font-size: 11px;
}
.cw-datastore {
    margin-bottom: 3px;
}
.cw-meta{
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
}
.EVENT .cw-meta{
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
}
.disclaimer{
  margin-top: 40px;
  border-top: 1px solid #000;
}
.disclaimer-copyright{
  display: block;
}
h1{
  margin: 0px;
  font-size: 32px;
  font-family: "myriad-pro-condensed-n6", "myriad-pro-condensed", verdana, sans-serif;
  font-weight: 600;
  font-style: normal;
}
h2{
  margin: 20px 0 0 0;
  font-size: 1.3em;
  line-height: 18px;
}
.document-view .SEC h2 {
  margin: 35px 0 15px;
  font-family: myriad-pro-n4,myriad-pro,verdana,sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.3em
}

.document-view > .SEC > h1 {
  font-weight: 500;
  font-size: 20pt;
  font-family: myriad-pro-condensed, sans-serif;
}

.document-view > .SEC > h1.subtitle {
  font-weight: 400;
  font-size: 14pt;
  font-family: "myriad-pro"
}

.document-view .SEC > p {
  color: #808285 !important;
  /*color: whitesmoke !important;*/
}

h3{
  margin: 30px 0 0 0;
  font-size: 16px;
  line-height: 16px;
}
.meta{
  margin: 0px;
}
p,
li{
  font-size: 13px !important;
  line-height: 16px;
  font-weight: normal;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  p, li {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJBQkFGRUZFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJBQkFGRjBFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkFCQUZFREU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkFCQUZFRUU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtrV+I8AAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC', sizingMethod=crop);
    zoom: 1;
    font-weight: 300;
  }
  body {
    /* IE4-8 and 9 (deprecated). */
    filter: Gray();
    /* SVG version for IE10, Chrome 17, FF3.5,
       Safari 5.2 and Opera 11.6 */
    filter: url('#grayscale');
    /* CSS3 filter, at the moment Webkit only. Prefix it for
       future implementations */
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); /* future-proof */
  }
  img {
    filter: none;
  }
}

@supports (-ms-accelerator:true) {
  p, li {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJBQkFGRUZFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJBQkFGRjBFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkFCQUZFREU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkFCQUZFRUU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtrV+I8AAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC',sizingMethod=crop);
    zoom: 1;
    font-weight: 300;
  }
  body {
    /* IE4-8 and 9 (deprecated). */
    filter: Gray();
    /* SVG version for IE10, Chrome 17, FF3.5,
       Safari 5.2 and Opera 11.6 */
    filter: url('#grayscale');
    /* CSS3 filter, at the moment Webkit only. Prefix it for
       future implementations */
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); /* future-proof */
  }
  img {
    filter: none;
  }
}

td,th{
  text-align: left;
  vertical-align: top;
  font-size: 13px;
  line-height: 16px;
}
td p, li{
  margin: 0 0 10px 0;
}
.EVENT th{
  width: 75px;
}
.disclaimer p{
  font-size: 12px!important;
}
.document-view li ul{
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em;
}
.document-view li ul li {
  list-style: none!important;
}
.document-view li ul li:before {
  content: "–";
  padding-right: 7px;
}
.document-view li ul li:first-child{
  margin-top: 10px;
}
